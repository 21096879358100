import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'next-i18next';

import NextLink from '@components/atoms/link';
import MenuItemLinkStyle from '@components/atoms/menuLinkList/style';
import { ICategory } from 'src/interfaces';

export type MenuItemLinkProps = {
  id?: number;
  link?: string;
  name: string;
  tName?: string;
  isAlert?: boolean;
  parent_category_id?: number;
  children?: ICategory[];
  setCategory?: (category: MenuItemLinkProps) => void;
  isSubcategoryMenu?: boolean;
  isDesktop?: boolean;
  isDisableHyperlink?: boolean;
  isShowSuffixIcon?: boolean;
  hoverCategoryId?: number;
  isOpenMenu?: boolean;
  onClick?: () => void;
};

const Menu = ({ tName, name, isAlert, isShowSuffixIcon = false, onClick }: MenuItemLinkProps) => {
  const { t } = useTranslation();

  const suffixIcon = isShowSuffixIcon && (
    <FontAwesomeIcon icon={faChevronRight} style={{ width: '12px', height: '12px' }} />
  );

  return (
    <MenuItem key={name} dense onClick={onClick}>
      <div className="item">
        <div className="name">{tName ? t(tName) : name}</div>
        {isAlert && <div className="alert-dot" />}
        {suffixIcon}
      </div>
    </MenuItem>
  );
};

export const MenuItemLink = (props: MenuItemLinkProps) => {
  const {
    id,
    link,
    parent_category_id,
    setCategory,
    isSubcategoryMenu,
    isDesktop,
    hoverCategoryId,
    isOpenMenu,
    isDisableHyperlink = false,
  } = props;

  const handleCategory = () => {
    if (isDesktop) {
      if (parent_category_id === null && setCategory && isOpenMenu) {
        setCategory(props);
      }
    } else if (parent_category_id === null && setCategory) {
      setCategory(props);
    }
  };

  const menuItem =
    isDisableHyperlink === false && link ? (
      <NextLink href={link}>
        <Menu {...props} />
      </NextLink>
    ) : (
      <Menu {...props} />
    );

  return (
    <>
      {isDesktop ? (
        <MenuItemLinkStyle onMouseEnter={handleCategory} isActive={hoverCategoryId === id}>
          {isSubcategoryMenu || parent_category_id === null ? menuItem : menuItem}
        </MenuItemLinkStyle>
      ) : (
        <MenuItemLinkStyle onClick={handleCategory}>
          {isSubcategoryMenu || parent_category_id === undefined ? menuItem : menuItem}
        </MenuItemLinkStyle>
      )}
    </>
  );
};

export type MenuLinkListProps = {
  list: Array<MenuItemLinkProps> | undefined;
  setCategory?: (category: MenuItemLinkProps & any) => void;
  isSubcategoryMenu?: boolean;
  isDesktop?: boolean;
  hoverCategoryId?: number;
  isOpenMenu?: boolean;
  isDisableHyperlink?: boolean;
};

export const MenuLinkList = ({
  list,
  setCategory,
  isSubcategoryMenu,
  isDesktop,
  hoverCategoryId,
  isOpenMenu,
  isDisableHyperlink = false,
}: MenuLinkListProps) => (
  <>
    {list?.map((menu: MenuItemLinkProps) => {
      const isShowSuffixIcon = menu.parent_category_id === null && !isSubcategoryMenu;
      return (
        <MenuItemLink
          key={menu.name}
          {...menu}
          setCategory={setCategory}
          isSubcategoryMenu={isSubcategoryMenu}
          isDesktop={isDesktop}
          hoverCategoryId={hoverCategoryId}
          isOpenMenu={isOpenMenu}
          isDisableHyperlink={isDisableHyperlink}
          isShowSuffixIcon={isShowSuffixIcon}
        />
      );
    })}
  </>
);
